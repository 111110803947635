<template>
    <div class="main">
        <div class="tit">{{chuan.title}}</div>
        <div class="neirong">
            <div class="lanzi">【事项简介】</div>
            <div v-html="chuan.contents"></div>
        </div>
        <div class="neirong">
            <div class="lanzi">【办理材料】</div>
            <div v-html="chuan.materials"></div>
        </div>
        <div class="neirong">
            <div class="lanzi">【注意事项】</div>
            <div v-html="chuan.remarks"></div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref, getCurrentInstance, computed ,onMounted} from 'vue'
import {Toast ,Dialog, Notify} from 'vant';
export default {
    name: "znzyList",
    setup() {
        const { proxy } = getCurrentInstance();
        const chuan = proxy.$root.$route.params; //前一页传入的数据
        console.log(chuan);
        
        return{
            chuan
        }
    }
}
</script>

<style scoped>
    .main {
        /* margin: 0; */
        padding:20px 10px;
        min-height: 100vh;
        background-color: #EDEDED;
        color: #555;
    }
    .tit{
        text-align: center;
        /* background-color: #fff;s */
        padding: 10px;
        margin: 0px 0 15px 0;
        font-size: 20px;
    }
    .neirong{
        margin: 10px 0;
        background-color: #fff;
        padding: 10px;
    }
    .lanzi{
        color: #1989fa;
        margin-bottom: 10px;
    }
</style>